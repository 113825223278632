import Auth from "@aws-amplify/auth"
import API from "@aws-amplify/api"
import Cache from "@aws-amplify/cache"

import { navigate } from "@reach/router"
import moment from "moment"

// import { loadPublisherTemplates } from "./createPublisher"
import { unauthorizedMessage } from "../utils/constants"

export function clearCache() {
  return dispatch => {
    dispatch({
      type: "USER_LOGOUT",
    })

    Cache.clear()
    Auth.signOut()
      .then(user => {
        console.log("actions - userManagement - logoutUser - user")
        console.log(user)
        navigate("/")
      })
      .catch(error => {
        console.log("actions - userManagement - logoutUser - error")
        console.log(error)
      })
  }
}

export function logoutUser(message) {
  return dispatch => {
    dispatch({
      type: "USER_LOGOUT",
      data: message,
    })

    Auth.signOut()
      .then(user => {
        console.log("actions - userManagement - logoutUser - user")
        console.log(user)
        Cache.removeItem("cid")

        navigate("/")
      })
      .catch(error => {
        console.log("actions - userManagement - logoutUser - error")
        console.log(error)
      })
  }
}

export function checkPasswordStrength(password, password2) {
  if (
    password === password2 &&
    password.length >= 8 &&
    /[a-z]/.test(password) &&
    /[A-Z]/.test(password) &&
    /[0-9]/.test(password) &&
    /[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`]/.test(password)
  ) {
    return true
  } else {
    return false
  }
}

function getUserRoles(sub, userObject) {
  return new Promise((resolve, reject) => {
    let apiName = "Storylavaweb"

    let path = "utility/admin/auth/company/list"

    let jwtToken = userObject.signInUserSession.accessToken.jwtToken
    let options = {
      headers: {
        Authorization: jwtToken,
      },
      // queryStringParameters: {
      //   m: "list",
      // },
    }

    //begin promise
    API.get(apiName, path, options)
      .then(response => {
        const data = response || {}

        console.log("actions - getUserRoles - API.get")
        console.log(data)

        resolve(data)
      })
      .catch(error => {
        console.log("actions - getUserRoles - API.get - error")
        console.log(error)
        reject()
      })
  }) //end promise
}

export function getCompanyDetail(cid, context, loadUserDetail) {
  return dispatch => {
    getCompanyDetailData(cid, context)
      .then(result => {
        console.log("getCompanyDetail - result")
        console.log(result)
        console.log("getCompanyDetail - context")
        console.log(context)
        dispatch({
          type: "COMPANY_DETAIL_REPLACE",
          data: result,
        })

        if (context === "users") {
          dispatch({
            type: "USERGROUP_CREATE_UPDATE_COMPLETE",
          })
          setTimeout(function() {
            dispatch({
              type: "USERGROUP_CREATE_UPDATE_RESET",
            })
          }, 5000)
        }
        if (loadUserDetail) {
          dispatch({
            type: "PROFILE_EDIT_COPY",
            data: result.user,
          })
          // dispatch({
          //   type: "PROFILE_EDIT_EMAIL_COPY",
          //   data: { email: result.user.email, verifycode: "" },
          // })
        }
      })
      .catch(error => {
        console.log(
          "actions - userManagement - loginUser - getComgetCompanyDetailpanyDetailPlain - error2"
        )
        console.log(error)
      })
  }
}
function getCompanyDetailData(cid, context) {
  return new Promise((resolve, reject) => {
    if (!cid) cid = Cache.getItem("cid")

    let apiName = "Storylavaweb"

    let path = "utility/admin/company/detail"

    Auth.currentAuthenticatedUser().then(user => {
      //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
      console.log("actions - getCompanyDetailData - auth - user")
      console.log(user)

      let jwtToken = user.signInUserSession.accessToken.jwtToken
      let options = {
        headers: {
          Authorization: jwtToken,
        },
        queryStringParameters: {
          cid,
          sub: user.username,
        },
      }
      if (context) options.queryStringParameters.context = context

      //begin promise
      API.get(apiName, path, options)
        .then(response => {
          const data = response || {}

          console.log("actions - getCompanyDetailData - API.get")
          console.log(data)

          resolve(data)
        })
        .catch(error => {
          console.log("actions - getCompanyDetailData - API.get - error")
          console.log(error)
          reject()
        })
    }) //end promise
  }).catch(error => {
    console.log("actions - getCompanyDetailData - getAuth - error")
    console.log(error)
  }) //end get Auth token
}

export function refreshLoginData() {
  return dispatch => {
    return new Promise(resolve => {
      // dispatch({
      //   type: "USERPROFILE_CREATE_UPDATE_START",
      // })
      Auth.currentAuthenticatedUser().then(user => {
        return resolve(
          dispatch(getLoginData(user, { pageid: "reload" }, false))
        )
        // return resolve(
        //   dispatch({
        //     type: "USERPROFILE_CREATE_UPDATE_START",
        //   })
        // )
      })
    })
  }
}
export function getLoginData(user, redirect, emailverified) {
  return dispatch => {
    let sub
    if (user && user.attributes && user.attributes.sub) {
      sub = user.attributes.sub
      if (sub === "ebf82958-af76-4620-bf1d-6b125a68904d") {
        //okay dave staging
      } else if (
        sub === "d6d3929d-ece9-4baf-8279-f509d4d279a4" ||
        sub === "c780fab9-c6a1-4a8b-bb17-a64ce675d266"
      ) {
        //okay dave or terry prod
      } else {
        return // added for admin
      }
    } else if (user && user.username) {
      sub = user.username
      return // added for admin
    } else {
      dispatch({
        type: "USER_LOGIN_ERROR",
        data: {
          message: "We weren't able to log you in",
        },
      })
      return
    }

    dispatch({
      type: "LOADING_START",
    })

    getUserRoles(sub, user)
      .then(result => {
        console.log(
          "actions - userManagement - loginUser - getUserRoles - result"
        )
        console.log(result)

        let selectedCompany = result.find(({ selected }) => selected === true)

        console.log(
          "actions - userManagement - loginUser - getUserRoles - selectedCompany"
        )
        console.log(selectedCompany)

        //null comparison is with two equal signs, See: https://stackoverflow.com/questions/2647867/how-can-i-determine-if-a-variable-is-undefined-or-null
        if (selectedCompany == null && result[0]) {
          selectedCompany = result[0]
        } else if (!result) {
          dispatch({
            type: "USER_LOGIN_ERROR",
            data: {
              message: "Couldn't get companies associated with this user",
            },
          })
          return
        }

        const expiration = moment()
          .add(1, "day")
          .valueOf()
        console.log(
          "actions - userManagement - loginUser - getUserRoles - expiration"
        )
        console.log(expiration)
        Cache.setItem("cid", selectedCompany.cid, { expires: expiration })

        getCompanyDetailData(selectedCompany.cid, sub)
          .then(result2 => {
            console.log("getCompanyDetailData - result2")
            console.log(result2)
            if (emailverified) {
              dispatch(
                updateUserProfile(
                  { emailverified: true },
                  null,
                  selectedCompany.cid
                )
              )
            }
            dispatch({
              type: "COMPANY_DETAIL_REPLACE",
              data: result2,
            })
            let userObj = {
              action: "routeHome",
              user,
              sub,
              cdata: result,
              // selectedCid: selectedCompany.cid,
            }
            dispatch({
              type: "USER_LOGIN",
              data: userObj,
            })
            console.log("redirect - getLoginData - redirect")
            console.log(redirect)
            if (
              redirect &&
              redirect.pageid &&
              redirect.pageid === "edit" &&
              redirect.templateid
            ) {
              // dispatch(
              //   loadPublisherTemplates({
              //     id: redirect.templateid,
              //     context: "image",
              //   })
              // )
            } else if (
              redirect &&
              redirect.pageid &&
              redirect.pageid === "reload"
            ) {
              console.log("stay here")
            } else {
              navigate("/app/composite/")
            }
          })
          .catch(error2 => {
            console.log(
              "actions - userManagement - loginUser - getCompanyDetailData - error2"
            )
            console.log(error2)
            dispatch({
              type: "USER_LOGIN_ERROR",
              data: error2,
            })
          })
      })
      .catch(error => {
        console.log(
          "actions - userManagement - loginUser - getUserRoles - error"
        )
        console.log(error)
        dispatch({
          type: "USER_LOGIN_ERROR",
          data: error,
        })
      })
  }
}

export function loginUser(username, password, redirect) {
  return dispatch => {
    dispatch({
      type: "LOADING_START",
    })

    Auth.signIn(username, password)
      .then(user => {
        console.log("actions - userManagement - loginUser - user")
        console.log(user)

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          console.log(
            "actions - userManagement - loginUser - NEW_PASSWORD_REQUIRED"
          )

          dispatch({
            type: "USER_LOGIN",
            data: { action: "forceNewPassword", user },
          })
        } else {
          console.log(
            "actions - userManagement - loginUser - NOT///NEW_PASSWORD_REQUIRED"
          )
          dispatch(getLoginData(user, redirect, false))
        } //end if/else NEW_PASSWORD_REQUIRED
      })
      .catch(error => {
        console.log("actions - userManagement - loginUser - error")
        console.log(error)
        dispatch({
          type: "USER_LOGIN_ERROR",
          data: error,
        })
      })
  }
}

export function updateEmailVerifyErrors(val) {
  return dispatch => {
    console.log("**updateEmailVerifyErrors")
    console.log(val)
    dispatch({
      type: "EMAILVERIFY_SEND_ERRORS",
      data: val,
    })
  }
}
export function updateEmailVerifyField(val) {
  return dispatch => {
    console.log("**updateEmailVerifyField")
    console.log(val)
    dispatch({
      type: "EMAILVERIFY_FIELD_UPDATE",
      data: val,
    })
    dispatch({
      type: "EMAILVERIFY_SEND_ERRORS",
    })
  }
}

export function updateUserProfile(formdata, statedata, providedCid) {
  console.log("actions - updateUserProfile - start")
  console.log("actions - updateUserProfile - formdata")
  console.log(formdata)
  console.log("actions - updateUserProfile - statedata")
  console.log(statedata)

  let cid = providedCid ? providedCid : Cache.getItem("cid")

  console.log("actions - updateUserProfile - cid")
  console.log(cid)

  //Figure out what is changed from initial state

  let userUpdateData = {}
  if (formdata.emailverified) userUpdateData.emailverified = true
  if (formdata.mobileverified) userUpdateData.mobileverified = true

  if (formdata.fname && formdata.fname !== statedata.fname) {
    userUpdateData.fname = formdata.fname
  }
  if (formdata.lname && formdata.lname !== statedata.lname) {
    userUpdateData.lname = formdata.lname
  }
  if (formdata.description && formdata.description !== statedata.description) {
    userUpdateData.description = formdata.description
  }
  if (formdata.photo && formdata.photo !== statedata.photo) {
    userUpdateData.photo = formdata.photo
  }
  if (formdata.email && formdata.email !== statedata.email) {
    userUpdateData.email = formdata.email
  }
  if (formdata.displayname && formdata.displayname !== statedata.displayname) {
    userUpdateData.displayname = formdata.displayname
  }
  if (
    formdata.mobile &&
    formdata.mobile.format &&
    formdata.mobile.format !== statedata.mobile.format
  ) {
    userUpdateData.mobile = formdata.mobile.format
  }

  if (
    formdata.hasOwnProperty("emailnotify") &&
    formdata.emailnotify !== statedata.emailnotify
  ) {
    userUpdateData.emailnotify = formdata.emailnotify
  }
  if (
    formdata.hasOwnProperty("mobilenotify") &&
    formdata.mobilenotify !== statedata.mobilenotify
  ) {
    userUpdateData.mobilenotify = formdata.mobilenotify
  }

  console.log("userManagement - updateUserProfile - userUpdateData")
  console.log(userUpdateData)

  if (Object.keys(userUpdateData).length === 0) {
    return
    //TODO: display error message
  }

  let apiName = "Storylavaweb"

  let path = "utility/admin/userprofile/update"

  return dispatch => {
    return new Promise(resolve => {
      dispatch({
        type: "USERPROFILE_CREATE_UPDATE_START",
      })
      Auth.currentAuthenticatedUser()
        .then(user => {
          //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
          console.log(
            "actions - updateUserProfile - Auth.currentAuthenticatedUser - user"
          )
          console.log(user)
          let jwtToken = user.signInUserSession.accessToken.jwtToken

          let options = {
            headers: {
              Authorization: jwtToken,
            },
            queryStringParameters: {
              cid,
            },
            body: {
              id: user.username,
              cid,
              ...userUpdateData,
            },
          }

          console.log(
            "actions - updateUserProfile - Auth.currentAuthenticatedUser - options"
          )
          console.log(options)

          //begin promise

          API.post(apiName, path, options)
            .then(response => {
              const data = response || {}

              let result = data

              console.log("actions - updateUserProfile - API.post - result")
              console.log(result)

              // navigate("/app/sendgroup/")

              // dispatch({
              //   type: "PROFILE_EDIT_EMAIL_COPY",
              //   data: { email: result.user.email, verifycode: "" },
              // })

              if (result.displayname && result.displayname === "ERROR") {
                return resolve(
                  dispatch({
                    type: "EMAILVERIFY_SEND_ERRORS",
                    data:
                      "This display name is already taken. Please try another one.",
                  })
                )
              } else {
                dispatch({
                  type: "PROFILE_EDIT_COPY",
                  data: result,
                })
                dispatch({
                  type: "USER_PROFILE_DETAIL_REPLACE",
                  data: result,
                })
                return resolve(
                  setTimeout(() => {
                    dispatch({
                      type: "USER_PROFILE_DETAIL_COMPLETE",
                    })
                  }, 5000)
                )
              }
            })
            .catch(error => {
              console.log("actions - updateUserProfile - catch")
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        })
        .catch(error => {
          console.log(error)
        }) //end get Auth token
    }) //end promise
  }
}
// function updateUserProfileLocal() {
//   return new Promise((resolve, reject) => {
//   }) //end promise
// }

export function verifyUserEmail(prop, verifycode) {
  return dispatch => {
    Auth.verifyCurrentUserAttributeSubmit(prop, verifycode)
      .then(result => {
        console.log("verifyUserEmail - result")
        console.log(result)
        let formData = {}
        if (prop === "email") formData.emailverified = true
        if (prop === "phone_number") formData.mobileverified = true

        dispatch(updateUserProfile(formData)).then(result2 => {
          dispatch({
            type: "EMAILVERIFY_UPDATE_COMPLETE",
            data: result,
          })
          return dispatch({
            type: "EMAILVERIFY_FIELD_UPDATE",
            data: "SUCCESS",
          })
        })
      })
      .catch(error => {
        console.log("verifyUserEmail - error")
        console.log(error)
        dispatch({
          type: "EMAILVERIFY_UPDATE_COMPLETE",
          error:
            error && error.message
              ? error.message
              : "We've encountered an error",
        })
        return dispatch({
          type: "EMAILVERIFY_FIELD_UPDATE",
          data: true,
        })
      })
  }
}
